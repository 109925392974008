
import { Vue, Component, Prop } from "vue-property-decorator";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { captureSentryException } from "@/plugins/sentry";
import TextLoadingSpinner from "@/components/TextLoadingSpinner.vue";
import ShareButton from "@/components/ShareButton.vue";
import { mdiContentCopy } from "@mdi/js";

@Component({
    components: { ShareButton, TextLoadingSpinner },
})
export default class ShareAlert extends Vue {
    @Prop({ required: true, type: String }) eneoCode!: string;

    copyIcon: string = mdiContentCopy;

    async copyEneoCode() {
        addAnalyticsEvent("eneo_prepaid_code_copy");
        try {
            await navigator.clipboard.writeText(this.eneoCode);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                "Your ENEO code has been copied to your clipboard"
            );
        } catch (error) {
            captureSentryException(error);
        }
    }
}
