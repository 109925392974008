import { render, staticRenderFns } from "./ShareButton.vue?vue&type=template&id=3350c6d7"
import script from "./ShareButton.vue?vue&type=script&lang=ts"
export * from "./ShareButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports